<template>
  <v-app id="inspire">
    <ShowMessage ref="message"></ShowMessage>
    <v-main>
      <v-container class="fill-height" style="background-color: white;" fluid>
        <v-row align="center" justify="center">
          <v-col cols="20" sm="8" md="8">
            <v-card class="elevation-12">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="12" md="5" style="background-color: #1d4ed8;">
                      <v-card-text class="white--text mt-1">
                        <img
                          src="../assets/logo_work_branco.png"
                          style="width: 80%; margin-top: 10%;"
                          class="caminhao"
                          alt=""
                        />
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-card-text class="mt-12">
                        <h3 class="text-center display-1" style="color: #1d4ed8;">
                          Login
                        </h3>
                        <v-form>
                          <v-text-field
                            v-model="username"
                            label="Usuário"
                            name="Usuario"
                            type="text"
                            style="color: #1d4ed8;"
                            autocomplete="username"
                          />

                          <v-text-field
                            v-model="password"
                            id="password"
                            label="Senha"
                            name="password"
                            autocomplete="current-password"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass = !showPass"
                            :type="showPass ? 'text' : 'password'"
                            style="color: #1d4ed8;"
                            @keyup.enter="entrar()"
                          />
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-3">
                        <v-btn
                          class="mr-3"
                          rounded
                          style="background-color: #1d4ed8; color: white; width: 30%; margin-bottom: 30px;"
                          @click="entrar()"
                        >
                          Entrar
                        </v-btn>
                        <v-btn
                          class="ml-3"
                          rounded
                          style="background-color: #1d4ed8; color: white; margin-bottom: 30px;"
                          @click="primeiroAcesso()"
                        >
                          Novo Transportador
                        </v-btn>
                      </div>
                      <div class="text-center mt-4">
                        <a
                          href="#"
                          @click.prevent="abrirVideo('bsNtidJyBLY')"
                          style="color: #1d4ed8; font-size: 14px;"
                        >
                          O que é Work CTe?
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>

        <div
          style="position: absolute; bottom: 20px; width: 100%; text-align: center;"
        >
          <span style="color: #1d4ed8; font-weight: bold; font-size: 16px;">
            Um produto
          </span>
          <img
            src="../assets/_logo_blue.png"
            alt="Logo"
            style="max-width: 100px; vertical-align: middle; margin-left: 8px;"
          />
        </div>

        <div class="workinho-container">
          <a
            href="#"
            @click.prevent="abrirVideo('nvtjKJqbWus')"
            style="text-decoration: none;"
          >
            <img
              src="../assets/workinho.png"
              alt="Nova Logo"
              style="max-width: 80px; vertical-align: middle; margin-bottom: 5px;"
            />
            <div style="color: #1d4ed8; font-weight: bold; font-size: 14px;">
              Dúvidas sobre o cadastro de <br />transportador? Clique aqui!
            </div>
            <v-icon style="color: #1d4ed8; margin-top: 3px;">mdi-youtube</v-icon>
          </a>
        </div>
      </v-container>
      <YoutubePlayer 
        v-if="showYoutubePlayer" 
        :videoId="currentVideoId" 
        @close="showYoutubePlayer = false" 
      />
    </v-main>

    <v-dialog v-model="dialogCadastro" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastre seus dados de transportador</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                ref="cpfCnpjInput"
                label="CNPJ"
                v-model="item.cpfCnpj"
                maxlength="18"
                @input="formatarCnpj(item.cpfCnpj)"
                @blur="consultarCnpj"
                :disabled="item.id > 0 ? true : camposDesabilitado"
                v-numbers-only
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                ref="razaoSocialInput"
                label="Razão Social"
                maxlength="60"
                v-model="item.razaoSocial"
                @input="item.razaoSocial = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                ref="nomeFantasiaInput"
                label="Nome Fantasia"
                maxlength="60"
                v-model="item.nomeFantasia"
                @input="item.nomeFantasia = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                ref="inscricaoEstadualInput"
                maxlength="14"
                label="Inscrição Estadual"
                v-model="item.inscricaoEstadual"
                v-numbers-only
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                ref="logradouroInput"
                label="Logradouro"
                maxlength="60"
                v-model="item.logradouro"
                @input="item.logradouro = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                ref="numeroInput"
                label="Número"
                maxlength="10"
                v-model="item.numero"
                @input="item.numero = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                ref="cepInput"
                label="CEP"
                v-model="item.cep"
                @input="aplicarMascaraCep"
                maxlength="8"
                :disabled="camposDesabilitado"
                v-numbers-only
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                ref="bairroInput"
                label="Bairro"
                maxlength="60"
                v-model="item.bairro"
                @input="item.bairro = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                ref="complementoInput"
                label="Complemento"
                maxlength="60"
                v-model="item.complemento"
                @input="item.complemento = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-select
                ref="ufInput"
                v-model="item.uf"
                :items="estados"
                label="UF"
                item-text="sigla"
                item-value="sigla"
                @input="loadMunicipios"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                ref="municipioInput"
                v-model="item.municipio"
                :items="municipios"
                label="Município"
                item-text="nome"
                item-value="nome"
                @input="atualizarCodigoIbge"
                :disabled="camposDesabilitado || !this.item.uf"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="foneInput"
                label="Fone"
                v-model="item.fone"
                @input="aplicarMascaraTelefone"
                v-numbers-only
                maxlength="15"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                ref="averbacaoApiInput"
                label="Empresa Seguro"
                v-model="item.averbacaoApi"
                :items="averbacaoApiitems"
                item-text="description"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                label="Email"
                ref="emailInput"
                maxlength="60"
                v-model="item.email"
                @input="item.email = $event.toLowerCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                label="Observação"
                maxlength="200"
                v-model="item.observacao"
                @input="item.observacao = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                ref="regimeInput"
                label="Regime"
                v-model="item.regime"
                :items="regimeitems"
                item-text="description"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelar"
            style="background-color: #1d4ed8; color: white;"
          >
            Cancelar
          </v-btn>
          <span style="margin-left: 10px; font-size: 12px;">
            *Passagem entre os campos usar a tecla "TAB"
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvar"
            style="background-color: #1d4ed8; color: white;"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import LoginService from "../service/login";
import { mapActions, mapMutations } from "vuex";
import ShowMessage from "../components/ShowMessage";
import Transportador from "../service/transportador";
import Logradouro from "../service/logradouros";
import YoutubePlayer from "../components/Youtube.vue";

export default {
  name: "Login",
  components: {
    ShowMessage,
    YoutubePlayer,
  },
  directives: {
    "numbers-only": {
      bind(el) {
        el.addEventListener("input", function (event) {
          const inputValue = event.target.value;
          event.target.value = inputValue.replace(/\D/g, "");
        });
      },
    },
  },
  data: () => ({
    showYoutubePlayer: false,
    currentVideoId: null, // ID do vídeo atual
    username: "",
    password: null,
    showPass: false,
    showPassCert: false,
    step: 1,
    dialogCadastro: false,
    camposDesabilitado: false,
    estados: [],
    municipios: [],
    selectedUF: null,
    selectedMunicipio: null,
    certificadoFile: null,
    item: {
      id: 0,
      razaoSocial: "",
      nomeFantasia: "",
      cpfCnpj: "",
      inscricaoEstadual: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      codigoIbge: null,
      municipio: "",
      cep: "",
      uf: "",
      fone: "",
      email: "",
      observacao: "",
      regime: 1,
      averbacaoApi: null,
      ativo: false,
      certificadoDataExpiracaoCertificado: "",
      certificadoSenha: "",
      tipoParticipante: "TRANSPORTADOR",
      preCadastro: true,
    },
    regimeitems: [
      { value: 0, description: "SIMPLES NACIONAL" },
      { value: 1, description: "LUCRO PRESUMIDO" },
      { value: 2, description: "NORMAL" },
    ],
    averbacaoApiitems: [
      { value: 0, description: "NENHUM" },
      { value: 1, description: "ATM" },
      { value: 2, description: "SMART HUB" },
      { value: 3, description: "PORTO SEGURO" },
    ],
  }),
  computed: {
    params() {
      return {
        username: this.username,
        password: this.password,
        isAuthenticated: false,
      };
    },
  },
  created() {
    this.loadEstados();
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),
    entrar() {
      this.setLoading(true, "Carregando dados");
      LoginService.login(this.username, this.password)
        .then((response) => {
          localStorage.setItem("permissoes", response.data.permissoes);
          localStorage.setItem("token", response.data.tokenAcesso);
          // Armazena a data/hora de expiração do token (30 minutos a partir do login)
          const currentTime = Math.floor(Date.now() / 1000); // tempo atual em segundos
          const tokenDuration = 30 * 60; // 30 minutos em segundos
          const tokenExpiration = currentTime + tokenDuration;
          localStorage.setItem("tokenExpiration", tokenExpiration);
          this.$router.go("/dashboard");
        })
        .catch(() => {
          this.snackError("Usuário e/ou senha incorretos");
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    primeiroAcesso() {
      this.item = {
        id: 0,
        razaoSocial: "",
        nomeFantasia: "",
        cpfCnpj: "",
        inscricaoEstadual: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        codigoIbge: null,
        municipio: "",
        cep: "",
        uf: "",
        fone: "",
        email: "",
        observacao: "",
        regime: null,
        averbacaoApi: null,
        ativo: false,
        certificadoDataExpiracaoCertificado: "",
        certificadoSenha: "",
        tipoParticipante: "TRANSPORTADOR",
        preCadastro: true,
      };
      this.dialogCadastro = true;
      this.$nextTick(() => {
        this.$refs.cpfCnpjInput.focus();
      });
    },
    abrirVideo(videoId) {
      this.currentVideoId = videoId;
      this.showYoutubePlayer = true;
    },
    limparCampos(cpfCnpj) {
      this.item = {
        id: 0,
        cpfCnpj: cpfCnpj,
        razaoSocial: "",
        nomeFantasia: "",
        inscricaoEstadual: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        codigoIbge: null,
        municipio: "",
        cep: "",
        uf: "",
        fone: "",
        email: "",
        observacao: "",
        regime: null,
        averbacaoApi: null,
        ativo: true,
        certificadoDataExpiracaoCertificado: "",
        certificadoSenha: "",
        tipoParticipante: "TRANSPORTADOR",
        preCadastro: true,
      };
    },
    formatarCnpj(cnpj) {
      if (!cnpj) return "";
      cnpj = cnpj.toString().replace(/\D/g, "");
      cnpj = cnpj.substr(0, 14);
      this.item.cpfCnpj = `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(
        5,
        3
      )}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`;
    },
    loadEstados() {
      Logradouro.getEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os estados: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os estados: " + error.message
            );
          }
        });
    },
    loadMunicipios() {
      if (this.item.uf) {
        Logradouro.getCidades(this.item.uf)
          .then((response) => {
            this.municipios = response.data;
          })
          .catch((error) => {
            if (error.response) {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades: " + error.response.data.mensagem
              );
            } else {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades: " + error.message
              );
            }
          });
      }
    },
    atualizarCodigoIbge() {
      const cidadeSelecionada = this.municipios.find(
        (cidade) => cidade.nome === this.item.municipio
      );
      if (cidadeSelecionada) {
        this.item.codigoIbge = cidadeSelecionada.codigoIbge;
      }
    },
    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/\D/g, "");
      if (cnpj.length !== 14) {
        return false;
      }
      const pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const digito1 = cnpj.charAt(12);
      const digito2 = cnpj.charAt(13);
      const calcularDigito = (cnpj, pesos) => {
        const soma = cnpj
          .split("")
          .slice(0, pesos.length)
          .map((d, i) => parseInt(d, 10) * pesos[i])
          .reduce((a, b) => a + b, 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
      };
      const valido =
        parseInt(digito1, 10) === calcularDigito(cnpj, pesos1) &&
        parseInt(digito2, 10) === calcularDigito(cnpj, pesos2);
      return valido;
    },
    consultarCnpj() {
      this.camposDesabilitado = true;
      if (!this.item.cpfCnpj) {
        this.camposDesabilitado = false;
        return;
      }
      if (!this.validarCNPJ(this.item.cpfCnpj)) {
        this.camposDesabilitado = false;
        this.$refs.message.showMsg("Atenção!", "CNPJ inválido! Favor tente novamente.");
        this.limparCampos();
        setTimeout(() => {
          this.$refs.cpfCnpjInput.focus();
        }, 0);
        return;
      }
      const cpfCnpj = this.item.cpfCnpj.replace(/\D/g, "");
      const tipo = "TRANSPORTADOR";
      Transportador.getCpfCnpj(cpfCnpj, tipo)
        .then((response) => {
          const data = response.data;
          if (!data.preCadastro) {
            this.camposDesabilitado = false;
            this.$refs.cpfCnpjInput.focus();
            this.$refs.message.showMsg("Atenção!", "CNPJ já cadastrado!");
            this.item.cpfCnpj = "";
            this.limparCampos();
            return;
          }
          const cepFormatado = data.endereco.cep
            ? data.endereco.cep.replace(/^(\d{5})(\d{3})$/, "$1-$2")
            : "";
          let telefoneFormatado = "";
          if (data.endereco && data.endereco.fone) {
            let telefone = data.endereco.fone.replace(/\D/g, "");
            if (telefone.length === 10) {
              telefoneFormatado = telefone.replace(
                /^(\d{2})(\d{4})(\d{4})$/,
                "($1) $2-$3"
              );
            } else if (telefone.length === 11) {
              telefoneFormatado = telefone.replace(
                /^(\d{2})(\d{5})(\d{4})$/,
                "($1) $2-$3"
              );
            } else {
              telefoneFormatado = telefone;
            }
          }
          this.item.id = data.id || "";
          this.item.razaoSocial = data.razaoSocial || "";
          this.item.nomeFantasia = data.nomeFantasia || "";
          this.item.inscricaoEstadual = data.inscricaoEstadual || "";
          this.item.logradouro = data.endereco.logradouro || "";
          this.item.numero = data.endereco.numero || "";
          this.item.complemento = data.endereco.complemento || "";
          this.item.bairro = data.endereco.bairro || "";
          this.item.codigoIbge = data.endereco.codigoIbge || "";
          this.item.municipio = data.endereco.municipio || "";
          this.item.cep = cepFormatado;
          this.item.uf = data.endereco.uf || "";
          this.item.fone = telefoneFormatado;
          this.item.email = data.email || "";
          this.item.observacao = data.observacao || "";
          this.item.regime = data.regime;
          this.item.averbacaoApi = data.averbacaoApi;
          this.loadMunicipios();
          this.camposDesabilitado = false;
          setTimeout(() => {
            this.$refs.razaoSocialInput.focus();
          }, 0);
        })
        .catch(() => {
          this.camposDesabilitado = false;
          this.limparCampos(this.item.cpfCnpj);
          setTimeout(() => {
            this.$refs.razaoSocialInput.focus();
          }, 0);
        });
    },
    aplicarMascaraCep() {
      this.item.cep = this.formatarCep(this.item.cep);
    },
    formatarCep(cep) {
      if (!cep) return "";
      const numerosCep = cep.replace(/\D/g, "");
      const limiteCaracteres = 8;
      const cepFormatado = numerosCep.substr(0, limiteCaracteres);
      return cepFormatado.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    aplicarMascaraTelefone() {
      this.item.fone = this.formatarTelefone(this.item.fone);
    },
    formatarTelefone(telefone) {
      if (!telefone) return "";
      const numerosTelefone = telefone.replace(/\D/g, "");
      const isCelular = numerosTelefone.length === 11;
      const limiteCaracteres = isCelular ? 11 : 10;
      const telefoneFormatado = numerosTelefone.substr(0, limiteCaracteres);
      return telefoneFormatado.replace(
        isCelular ? /^(\d{2})(\d{5})(\d{4})/ : /^(\d{2})(\d{4})(\d{4})/,
        isCelular ? "($1) $2-$3" : "($1) $2-$3"
      );
    },
    clickBtnSalvar() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormulario()) {
              if (this.item.id === 0) {
                Transportador.insertTransportador(this.item)
                  .then(() => {
                    this.snackSuccess(
                      "Salvo com Sucesso! Após a ánalise e confirmação, você receberá no seu e-mail um usuário para acesso."
                    );
                    this.dialogCadastro = false;
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o transportador: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o transportador: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateTransportador(this.item.id, this.item)
                  .then(() => {
                    this.snackSuccess(
                      "Salvo com Sucesso! Após a ánalise e confirmação, você receberá no seu e-mail um usuário para acesso."
                    );
                    this.dialogCadastro = false;
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o transportador: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o transportador: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.setLoading(false);
            }
          }
        }
      );
    },
    validarDadosFormulario() {
      this.item.razaoSocial = this.item.razaoSocial.trim();
      this.item.nomeFantasia = this.item.nomeFantasia.trim();
      this.item.inscricaoEstadual = this.item.inscricaoEstadual.trim();
      this.item.logradouro = this.item.logradouro.trim();
      this.item.numero = this.item.numero.trim();
      this.item.bairro = this.item.bairro.trim();
      this.item.email = this.item.email.trim();
      this.item.complemento = this.item.complemento.trim();

      if (!this.item.cpfCnpj) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo CNPJ é obrigatório");
        return false;
      }

      if (!this.validarCNPJ(this.item.cpfCnpj)) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg("Atenção!", "CNPJ inválido! Favor tente novamente.");
        return false;
      }

      if (!this.item.razaoSocial) {
        this.$refs.razaoSocialInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Razão Social é obrigatório");
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.razaoSocial)) {
        this.$refs.razaoSocialInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!this.item.nomeFantasia) {
        this.$refs.nomeFantasiaInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Nome Fantasia é obrigatório");
        return false;
      }

      if (!this.item.inscricaoEstadual) {
        this.$refs.inscricaoEstadualInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Inscrição Estadual é obrigatório");
        return false;
      }

      if (!this.item.logradouro) {
        this.$refs.logradouroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Logradouro é obrigatório");
        return false;
      }

      if (!this.item.numero) {
        this.$refs.numeroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Número é obrigatório");
        return false;
      }

      if (!this.item.cep) {
        this.$refs.cepInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo CEP é obrigatório");
        return false;
      }

      if (!this.item.bairro) {
        this.$refs.bairroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Bairro é obrigatório");
        return false;
      }

      if (this.item.codigoIbge <= 0) {
        this.$refs.codigoIbgeInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Código do IBGE é obrigatório");
        return false;
      }

      if (!this.item.municipio) {
        this.$refs.municipioInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Munícipio é obrigatório");
        return false;
      }

      if (!this.item.uf) {
        this.$refs.ufInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo UF é obrigatório");
        return false;
      }

      if (!this.item.fone) {
        this.$refs.foneInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Fone é obrigatório");
        return false;
      }

      if (!this.item.email) {
        this.$refs.emailInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo E-mail é obrigatório");
        return false;
      }

      if (this.item.regime < 0) {
        this.$refs.regimeInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Regime é obrigatório");
        return false;
      }

      if (this.item.averbacaoApi < 0) {
        this.$refs.averbacaoApiInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Empresa Seguro é obrigatório");
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.razaoSocial)) {
        this.$refs.razaoSocialInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.nomeFantasia)) {
        this.$refs.nomeFantasiaInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.inscricaoEstadual)) {
        this.$refs.inscricaoEstadualInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Inscrição Estadual deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.logradouro)) {
        this.$refs.logradouroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.numero)) {
        this.$refs.numeroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Numero deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.bairro)) {
        this.$refs.bairroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.complemento)) {
        this.$refs.complementoInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Complemento deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.item.observacao)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Observação deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!this.item.complemento) {
        this.item.complemento = "SEM COMP";
      }

      this.mensagem = "";
      return true;
    },
    clickBtnCancelar() {
      this.dialogCadastro = false;
    },
    onCertificadoFileChange() {
      console.log("Certificado selecionado: " + this.certificadoFile.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.caminhao {
  @media only screen and (max-width: 768px) {
    margin-top: 0%;
  }
}

.workinho-container {
  bottom: 80px; /* Ajusta a distância entre o workinho e o conteúdo */
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: center;
}

.produto-logo {
  position: fixed;
  bottom: 20px; /* Logo "Um produto" fixo, mais afastado */
  width: 100%;
  text-align: center;
}

.produto-logo span {
  font-size: 16px;
  color: #1d4ed8;
  font-weight: bold;
}

.produto-logo img {
  max-width: 100px;
  vertical-align: middle;
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  /* Ajustes para dispositivos móveis */
  .workinho-container {
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  .produto-logo {
    bottom: 100px; /* Ajusta a posição do logo para o mobile */
  }

  /* Adiciona espaço suficiente no fundo para o conteúdo */
  .v-container {
    padding-bottom: 150px; /* Ajuste para dar espaço ao workinho fixo */
  }
}
</style>
